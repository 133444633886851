var ApxDialog = {
    // template: '<div class="apxDialog apxDialog-modal"><div class="apxDialog--popup"><section class="apxDialog--content">{{nl2br content}}</section>{{#if buttons}}<footer class="apxDialog--footer">{{#each buttons}}<button name="{{@key}}" class="btn {{#if this.class}} {{this.class}}{{/if}}">{{this.text}}</button>{{/each}}</footer>{{/if}}</div></div>',
    render: function({content, buttons}) {
        let buttonsHtml = '';

        if(buttons) {
            buttonsHtml = '<footer class="apxDialog--footer">';

            for(let btnKey in buttons) {
                buttonsHtml += `<button name="${btnKey}" class="btn ${buttons[btnKey].class || ''}">${buttons[btnKey].text}</button>`;
            }
            buttonsHtml += '</footer>';
        }

        return `<div class="apxDialog apxDialog-modal"><div class="apxDialog--popup"><section class="apxDialog--content">${content.replace(/\n/g, '<br>')}</section>${buttonsHtml}</div></div>`;
    },
    initialized: false,
    isOpen: false,
    texts: {
        ok: 'OK',
        yes: 'Yes',
        no: 'No',
        cancel: 'Cancel',
        loading: '<img src="img/loading.gif">'
    },
    animations: {
        backdrop: {
            show: 'animated fadeIn',
            hide: 'animated fadeOut'
        },
        dialog: {
            show: 'animated-short scaleIn',
            hide: 'animated-short scaleOut'
        }
    },
    init: function () {
        this.initialized = true;
    },
    setTexts: function (texts) {
        this.texts = $.extend({}, this.texts, texts);
    },
    confirm: function (text, button_texts) {
        if (!button_texts) {
            button_texts = [this.texts.yes, this.texts.no];
        }
        var buttons = {
            no: {
                result: false,
                text: button_texts[1]
            },
            yes: {
                result: true,
                text: button_texts[0],
                class: 'btn-primary'
            }
        };

        return this.show(text, buttons);
    },
    prompt: function (text, placeholder, options) {
        if (!options) {
            options = {};
        }

        const button_texts = options.button_texts || [this.texts.ok, this.texts.cancel];

        var buttons = {
            no: {
                result: false,
                text: button_texts[1]
            },
            yes: {
                result: true,
                text: button_texts[0],
                class: 'btn-primary'
            }
        };

        text = '<p class="apxDialog--content--prompt">' + text + "</p>" + '<input name="apxDialogPrompt" placeholder="' + placeholder + '" value="' + (options.value || '') + '">';

        return this.show(text, buttons, 'apxDialogPrompt').then(function (result, data) {
            if (result) {
                return data.apxDialogPrompt;
            }
            return '';
        });
    },
    alert: function (text, button_text) {
        var buttons = {
            ok: {
                result: true,
                text: button_text || this.texts.ok,
                class: 'btn-primary'
            }
        };
        return this.show(text, buttons);
    },
    loading: function () {
        return this.show(this.texts.loading);
    },
    show: function (text, buttons, _return) {
        var self = this,
            promise = $.Deferred(),
            primaryButton;

        if (this.isOpen) {
            promise.reject('Cannot open apxDialog: already open');
            return promise;
        }
        if (!this.initialized) {
            this.init();
        }

        //            $('html, body').css('overflow', 'hidden');

        for (var btn in buttons) {
            if (buttons[btn].result) {
                // if the result is true, assume it's the primary button
                primaryButton = buttons[btn];
                // save the button name so we can check it later
                primaryButton.name = btn;
                break;
            }
        }

        this.isOpen = true;

        self.$elem = $(self.render({
            content: text,
            buttons: buttons
        })).on('click', '.apxDialog--footer .btn', function () {
            var data = {},
                form_data = self.$elem.find('input, textarea').serializeArray();

            for (var i = 0; i < form_data.length; i++) {
                data[form_data[i].name] = form_data[i].value;
            }

            self.hide().then(function () {
                if (buttons[this.name]) {
                    promise.resolve(
                        buttons[this.name].hasOwnProperty('result') ? buttons[this.name].result : this.name,
                        data
                    );
                } else {
                    promise.reject({
                        error: 'invalid button pressed',
                        button: this
                    });
                }
            }.bind(this));
        }).on('keyup', 'input', function (e) {
            if (e.which == 27) {
                // ESC
                self.hide();
                promise.reject();
            }
            if (primaryButton && e.which === 13) {
                self.$elem.find('.apxDialog--footer .btn[name="' + primaryButton.name + '"]').click();
            }
        }).appendTo(document.body);
        if (_return) {
            self.$elem.find('input[name="' + _return + '"]').focus();
        }
        self._addAnimation(self.$elem, this.animations.backdrop.show);
        self._addAnimation(self.$elem.find('.apxDialog--popup'), this.animations.dialog.show);

        return promise.promise();
    },
    hide: function () {
        var self = this;
        return $.when(
            this._addAnimation(this.$elem, this.animations.backdrop.hide, true),
            this._addAnimation(this.$elem.find('.apxDialog--popup'), this.animations.dialog.hide, true)
        ).then(function () {
            this.$elem.remove();
            self.isOpen = false;

            // $('html, body').css('overflow', '');
        }.bind(this));
    },
    _addAnimation: function ($el, animation_class, detach) {
        var promise = $.Deferred();
        $el.addClass(animation_class).one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
            if (detach) {
                $el.detach();
            } else {
                $el.removeClass(animation_class);
            }
            promise.resolve();
        });
        return promise.promise();
    }
};

export {
    ApxDialog,
};