var ApxBtnRadios = function ($elem) {

    this.$element = $elem;
    this.$element.data('apxBtnRadios', this);
    this.selected = undefined;

    this.init();

};

ApxBtnRadios.prototype.init = function () {
    var self = this;

    this.$element.on('change', 'input:radio', function (e) {
        e.stopPropagation();

        if (!this.checked) {
            return;
        }

        self.$element.find('.btn-radio').removeClass('btn--radios--selected');
        $(this).closest('.btn-radio').addClass('btn--radios--selected');

        self.selected = this.value;
        self.$element.trigger('change');
    }).find('input:radio').trigger('change');
};

export {
    ApxBtnRadios
};