import {
	ApxEventManager
} from "./apxEventManager";

const getObjectSize = function (obj) {
	var size = 0,
		key;
	for (key in obj) {
		if (obj.hasOwnProperty(key)) size++;
	}
	return size;
};

var defaultOptions = {
	validateOn: 'blur',
	// when a field is invalid, validate it on ...
	validateInvalidOn: 'keyup',
	validateOnLoad: false,
	validClass: 'valid',
	invalidClass: 'invalid',
	// returns the $element that gets the valid or invalid class
	classReceiver: function ($input) {
		return $input;
		//			return $input.parent();
	},
	cacheRules: true,
	rules: {
		required: /.+?/,
		tel: /^[\/\d\s-]*$/,
		email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9äöüÄÖÜ]+\.)+[a-zA-Z]{2,}))$/
	},
	validators: {
		requireRadioCheckbox: function ($input) {
			return $('input[name="' + $input[0].name + '"]:checked').length > 0;
		},
		number: function ($input) {
			var value = $input.val();

			//http://stackoverflow.com/questions/18082/validate-decimal-numbers-in-javascript-isnumeric#answer-1830844
			return !isNaN(parseFloat(value)) && isFinite(value);
		},
		min: function ($input) {
			var value = $input.val(),
				min = $input.attr('min');

			return value >= min;
		},
		max: function ($input) {
			var value = $input.val(),
				max = $input.attr('max');

			return value <= max;
		},
		step: function ($input) {
			var value = $input.val(),
				step = $input.attr('step');

			return value % step === 0;
		},
		range: function ($input) {
			//TODO implement "range" validator
			alert('"range"" validation is not yet implemented');
		}
	}
};

var ApxValidation = function ($form, options) {
	ApxEventManager.eventify(this);

	this.options = $.extend(true, {}, defaultOptions, options);

	this.$form = $form.attr('novalidate', true);
	this.$fields = $form.find(':input').not(':button, :submit');

	var self = this;

	$form.submit(function (e) {
		var field_errors,
			valid;

		field_errors = self.validateFields();
		valid = field_errors === true;

		//TODO do something with field_errors...

		self.fire('submit', {
			valid: valid,
			errors: valid ? {} : field_errors
		});
		if (!valid) {
			// is invalid
			e.preventDefault();
			e.stopPropagation();


			return false;
		}

		$form.data('valid', valid);
	});

	if (this.options.validateOn) {
		//			var ignoreKeys = [9,16];	//Tab, Shift
		this.$fields.on(this.options.validateOn + '.validate', function () {
			var $this = $(this);
			if ($this.data('is_dirty')) {
				self.showFieldErrors(this);
			}
			if ($this.data('valid')) {
				$this.off(self.options.validateInvalidOn);
			}
		}).on('change.validateIsDirty', function (e) {
			var $this = $(this),
				is_dirty = $this.data('is_dirty');

			if (is_dirty) {
				return;
			}

			//				if(-1 === $.inArray(e.which, ignoreKeys)) {
			$this.data('is_dirty', true);
			//				}
			$this.trigger('blur');
		});

		if (this.options.validateOn == 'blur') {
			this.$fields.filter(':checkbox, :radio').on('change', function () {
				$(this).trigger('blur');
				//					$('input[name="'+this.name+'"]:checked').trigger('blur');
			});
		}
	}

	if (this.options.validateOnLoad) {
		this.$fields.each(function () {
			self.showFieldErrors(this);
		});
	}
};

ApxValidation.prototype.validateFields = function ($fields) {
	var self = this,
		errors = {};

	if (undefined === $fields) {
		$fields = this.$fields;
	}

	$fields.each(function () {
		var field_errors = self.showFieldErrors(this);
		if (field_errors.length) {
			// valid = false;
			errors[this.name] = field_errors;
		}
	});

	if (Object.keys(errors).length) {
		return errors;
	} else {
		return true;
	}
};

ApxValidation.prototype.validateField = function (field) {
	var valid = true,
		$field = $(field),
		errors = [],
		rules = this.getValidationRules($field),
		r = 0;

	for (; r < rules.length; r++) {
		valid = this.validate(rules[r], $field);

		if (!valid) {
			errors.push(rules[r]);
			break;
		}
	}

	return errors;
};

ApxValidation.prototype.showFieldErrors = function (field) {
	var self = this,
		$field = (field.type == 'checkbox' || field.type == 'radio') ? this.$form.find('input[name="' + field.name + '"]') : $(field),
		$classReceiver = this.options.classReceiver($field),
		errors = this.validateField(field);

	$classReceiver.removeClass([this.options.validClass, this.options.invalidClass].join(' '));

	if (errors.length) {
		if (this.options.validateInvalidOn && $field.data('valid') !== false) {
			$field.on(this.options.validateInvalidOn, function () {
				self.showFieldErrors(this);
			});
		}

		$field.data('valid', false);
		$classReceiver.addClass(this.options.invalidClass);
	} else {
		//					$field.data('valid', true).off(this.options.validateInvalidOn);
		// don't remove validateInvalidOn, only in blur
		$field.data('valid', true);
		$classReceiver.addClass(this.options.validClass);
	}

	return errors;
};

ApxValidation.prototype.getValidationRules = function ($input) {
	var self = this,
		i = 0,
		rules = [],
		type,
		customRules, min, max, step,
		existingRules = this.options.cacheRules ? $input.data('validation-rules') : false;

	if (existingRules) {
		return existingRules;
	}

	type = $input.attr('inputmode') || $input.attr('type');
	customRules = $input.data('validate');

	if ($input.attr('required')) {
		rules.push('required');
	}

	switch (type) {
		case 'hidden':
		case 'text':
		case 'search':
		case 'submit':
			break;
		case 'checkbox':
		case 'radio':
			var reqPos = $.inArray('required', rules);
			if (-1 !== reqPos) {
				rules[reqPos] = 'requireRadioCheckbox';
			}
			break;
		case 'number':
			rules.push(type);

			if ($input.data('min')) {
				rules.push('min');
			}
			if ($input.data('max')) {
				rules.push('max');
			}
			if ($input.data('step')) {
				rules.push('step');
			}
			break;
			//			case 'range':
			//			case 'email':
			//			case 'url':
			//			case 'tel':
			//			case 'date':
			//			case 'month':
			//			case 'week':
			//			case 'time':
			//			case 'datetime':
			//			case 'datetime-local':
			//			case 'color':
		default:
			// allow any type. on validate it needs to be in options.rules or options.validators
			rules.push(type);
			break;
	}

	if ($input.attr('pattern')) {
		rules.push($input.attr('pattern'));
	}

	customRules = (customRules && customRules.split(',')) || [];
	for (; i < customRules.length; i++) {
		rules.push(customRules[i]);
	}

	// Cache validation rules
	if (this.options.cacheRules) {
		$input.data('validation-rules', rules);
	}

	return rules;
};
ApxValidation.prototype.validate = function (rule, $input) {
	var is_valid;

	if (this.options.validators[rule]) {
		is_valid = this.options.validators[rule]($input);

	} else if (this.options.rules[rule]) {
		is_valid = $input.val().match(this.options.rules[rule]);

	} else {
		// No validator found => interpret as RegEx
		is_valid = $input.val().match(rule);
	}

	return !!is_valid;
};

export {
	ApxValidation
};