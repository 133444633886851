import {
    ApxValidation
} from "./apxValidation";

var ApxForm = function ($element, options) {
    var that = this;

    this.settings = $.extend({}, this.defaults, options);
    this.$element = $element;

    this.setEvents();
    this.initConditionals();

    return this;
};
ApxForm.prototype.defaults = {
    validate: true,
    classes: {
        conditional: {
            visible: 'apxForm--conditional-visible',
            hidden: 'apxForm--conditional-hidden'
        }
    }
};
ApxForm.prototype.initConditionals = function () {
    var self = this;

    this.$element.find('.apxForm--conditional').each(function () {
        var $this = $(this),
            cond_field = $this.data('conditional'),
            $cond_field = cond_field ? $('input[name="' + cond_field + '"]') : false;

        self.validation.options.cacheRules = false;

        if (!$cond_field || !$cond_field.length) {
            //TODO show some kind of error
            return false;
        }

        $cond_field.on('change', function () {
            if (!this.value || this.value == '0') {
                $this
                    .removeClass(self.settings.classes.conditional.visible)
                    .addClass(self.settings.classes.conditional.hidden)
                    .find('input').each(function () {
                        var $this = $(this);
                        if ($this.attr('required')) {
                            $(this).attr('required', false).data('required', true);
                        }
                    });
            } else {
                $this.attr('required', true)
                    .removeClass(self.settings.classes.conditional.hidden)
                    .addClass(self.settings.classes.conditional.visible)
                    .find('input').each(function () {
                        var $this = $(this);
                        if ($this.data('required')) {
                            $this.attr('required', true);
                        }
                    });
            }
        }).filter(':checked').eq(0).trigger('change');

    });
};
//	Register.prototype.validatePage = function(page) {
//
//		var result = true,
//			self = this,
//			$page = this.$pages.eq(page - 1),
//			$inputs = $page.find('input, textarea');
//
//		if($page.data('novalidate')) {
//			return true;
//		}
//
//		$inputs.each(function() {
//			var errors = self.validation.showFieldErrors(this);
//			if(errors.length) {
//				result = false;
//			}
//		});
//
//		return result;
//	};
ApxForm.prototype.setEvents = function () {
    if (this.settings.validate) {
        this.validation = new ApxValidation(this.$element, {
            validateOn: '',
            validClass: 'ekim--form--valid',
            invalidClass: 'ekim--form--invalid',
            classReceiver: function ($input) {
                return $input.attr('type') === 'hidden' ? $input : $input.parent();
                //    				return $input.attr('type') === 'radio' ? $input.parent().parent() : $input.parent();
            }
        });

        var $formError = this.$element.find('.apxForm--error');

        this.validation.addListener('submit', function (e) {
            if (!e.data.valid) {
                //                    e.data.errors
                $formError.addClass('apxForm--error-visible');
            } else {
                $formError.removeClass('apxForm--error-visible');
            }
        });
    }
};

export {
    ApxForm
};