import {
    ApxForm
} from "./apxForm";
import {
    ApxDropdown
} from "./apxDropdown";
import {
    ProductFilter
} from "./productFilter";
import {
    ApxDialog
} from "./apxDialog";
import {
    ProductDetail
} from "./productDetail";
import * as DTde from "./DataTables_de.json";
import * as DTfr from "./DataTables_fr.json";
import {
    ApxBtnRadios
} from "./apxBtnRadios";
import {
    Search
} from "./search";

const $ = window.$ = window.jQuery = require('jquery');
var DataTables = require('datatables.net');
var buttons = require('datatables.net-buttons');

// const DataTables = require('./vendor/DataTables/js/jquery.dataTables');
require('datatables.net-buttons/js/buttons.colVis.js')();

const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
var $apxForms = $('.apxForm'),
    apxForms = [];

$apxForms.each(function () {
    apxForms.push(new ApxForm($(this), {}));
});

var $dropdowns = $('select.scrollMenu'),
    dropdowns = [];

$dropdowns.each(function () {
    dropdowns.push(new ApxDropdown($(this)));
});

$('.ekim--productFilter').each(function () {
    var pFilter = new ProductFilter($(this));
    //TODO add again after list rewrite
    //        $(function() {
    //            pFilter.loaded = true;
    //        });
});

ApxDialog.setTexts(eKiM.texts);

$(document).on('click', 'a.apxDialog_link', function (e) {
    var $this = $(this),
        type = $this.data('type'),
        text = $this.data('text'),
        dialog,
        href = this.href;

    e.preventDefault();

    switch (type) {
        default:
        case 'alert':
            dialog = ApxDialog.alert(text);
            break;
        case 'confirm':
            dialog = ApxDialog.confirm(text);
            break;
    }

    dialog.then(function (accepted) {
        if (accepted) {
            document.location.href = href;
        }
    });
});

var $details = $('.ekim--details');
if ($details.length) {
    ProductDetail.init($details);
}

$.fn.datatable = DataTables;

var $dataTables = $('.ekim--table-dt').each(function () {
    var $table = $(this),
        columnsSortable = [],
        columnsColVisExclude = [],
        columnsColVisInclude = [],
        columnDefs = [];

    // construct column configuration
    $table.find('tr:first th').each(function (i) {
        if (this.getAttribute('data-sortable') === 'false') {
            columnsSortable.push({
                'bSortable': false
            });
            columnsColVisExclude.push(i);
        } else {
            columnsSortable.push(null);
            columnsColVisInclude.push(i);
        }

        var columnDef = {
            targets: [i]
        };
        if (this.getAttribute('data-hidden') === 'true') {
            columnDef.visible = false;
        }
        if (this.getAttribute('data-searchable') === 'false') {
            columnDef.searchable = false;
        }
        columnDefs.push(columnDef);
    });

    $table.dataTable({
        aoColumns: columnsSortable,
        dom: 'BC<"clearfix">lfrtip',
        // dom: "<'row mx-0 mt-3 mb-1'<'col-sm-12 col-md-6 'l><'col-sm-12 col-md-6 text-right'f<'d-inline-block'B>>>" +
        //     "<'row'<'col-sm-12'tr>>" +
        //     "<'row mx-0 mt-2 mb-3'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        lengthChange: true,
        language: eKiM.language === 'de' ? DTde.default : DTfr.default,
        // colVis: {
        //     buttonText: eKiM.texts.visible_columns,
        //     exclude: columnsColVisExclude
        // },
        buttons: [
            {
                extend: 'colvis',
                text: eKiM.texts.visible_columns,
                columns: columnsColVisInclude,
            },
        ],
        columnDefs: columnDefs,
        stateSave: true,
        // use session storage instead of local storage
        stateDuration: -1,
        scrollX: '100%'
    });
});

$('.ekim--btn--saveCart').on('click', function (e) {
    e.preventDefault();
    var self = this;

    ApxDialog.prompt('Name des Warenkorbs:', 'Name').then(function (name) {
        if (name) {
            document.location.href = self.href + '/' + encodeURIComponent(name);
        }
    });
});

$('.ekim--setDefaultMode').on('click', function (e) {
    e.preventDefault();
    var self = this,
        buttons = {
            cancel: {
                result: false,
                text: ApxDialog.texts.cancel
            },
            ok: {
                result: true,
                text: ApxDialog.texts.ok,
                class: 'btn-primary'
            }
        };

    ApxDialog.show($(this).data('html'), buttons).then(function (result, data) {
        if (result) {
            $.post(self.href, {
                _token: csrfToken,
                name: 'default_view_mode',
                value: data.default_view_mode,
            }, undefined, 'json').then(function (data) {
                if (data.result) {
                    // document.location.refresh();
                }
            });
        }
    });
});

$('.ekim--cart--switchView').on('change', function (e) {
    var $this = $(this),
        $form = $this.closest('.ekim--cart');
    console.log('changing..', $this.data('apxBtnRadios').selected);

    switch ($this.data('apxBtnRadios').selected) {
        case '1':
            $form.removeClass('ekim--cart-showNetto').addClass('ekim--cart-showBrutto');
            break;
        case '2':
            $form.addClass('ekim--cart-showNetto').removeClass('ekim--cart-showBrutto');
            break;
    }

});

$('.btn--radios').each(function () {
    var apxBtnRadios = new ApxBtnRadios($(this));
});

Search.init();

var $searchLink = $('.ekim--header--img--menu--search a').on('click', function (e) {
    e.preventDefault();

    $(this).parent().toggleClass('ekim--header--img--menu--active');

    var $container = $('.ekim--search--container');
    $container.slideToggle();
    $container.find('input[type=search]').focus();
});

if (eKiM.current_route == 'search' || eKiM.current_route == 'search_post') {
    $searchLink.click();
}

$(".ekim--navigation--top--topmenu").click(function (e) {
    if (e.target.getAttribute('href') === '#') {
        e.preventDefault();
    }

    e.originalEvent.clickedSubmenu = true;
    $(".ekim--navigation--top--topmenu > ol").toggleClass("active");
});

$("body").click(function (e) {
    if (!e.originalEvent || !e.originalEvent.clickedSubmenu) {
        $(".ekim--navigation--top--topmenu > ol").removeClass("active");
    }
});

$("#mobileMenuButton").click(function () {
    $(".ekim--navigation--top--mobileMenu").slideToggle();
});

$(".ekim--login--details-btn").click(function () {
    $(".ekim--login--details-text").slideToggle();
});
