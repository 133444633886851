import {
    Notes
} from "./notes";
import {
    ApxDialog
} from "./apxDialog";

const $ = require('jquery');

var ProductDetail = {
    $elem: false,
    init: function ($elem) {
        this.$elem = $elem;
        this.csrfToken = document.querySelector('meta[name="csrf-token"]').content;

        this.$discount = this.$elem.find('input[name=discount]');
        this.$discount.val(eKiMProductPrices.discount);
        this.initFavorite();
        this.initCalculator();
        this.initList();

        var $notes = $elem.find('.ekim--details--notes');
        Notes.init($notes);

        this.calculatePrices();
    },
    initFavorite: function () {
        var self = this;
        this.$favorite = this.$elem.find('.ekim--productFavorite');

        this.$favorite.on('click', function (e) {
            e.preventDefault();

            $.post(this.href, {
                _token: self.csrfToken,
            }, undefined, 'json').then(function (data) {
                if (data.hasOwnProperty('is_favorite')) {
                    self.switchFavorite(data.is_favorite);
                } else {
                    //TODO maybe show error...
                }
            });
        });
    },
    switchFavorite: function (isFavorite) {
        this.$favorite.toggleClass('ekim--productFavorite-on', isFavorite)
            .toggleClass('ekim--productFavorite-off', !isFavorite);
    },
    initList: function () {
        var self = this;

        self.$elem.on('click', '.ekim--details--prices--list a', function (e) {
            e.preventDefault();

            var $addToCart = $(this),
                surface = $addToCart.closest('tr').data('surface');

            (function (self, surface) {
                ApxDialog.prompt(eKiM.texts.quantity, eKiM.texts.count, {
                    value: self.quantity
                }).then(function (amount) {
                    if (amount) {
                        var scale = self.getQuantityScale(amount),
                            price = self.calculatePrice(eKiMProductPrices.surfaces[surface][scale], eKiMProductPrices.discount, amount);

                        $.post(self.$elem.data('list-add-url'), {
                            _token: self.csrfToken,
                            product_id: self.$elem.data('product-id'),
                            surface_name: surface,
                            surface_id: eKiMProductPrices.surfaceIds[surface],
                            quantity: amount
                        }, 'json').then(function (data) {
                            if (data) {
                                if (data.amount == 0) {
                                    $addToCart.find('i').removeClass('icon-warenkorb_voll').addClass('icon-warenkorb_leer');
                                } else {
                                    $addToCart.find('i').add('.ekim--header--img--menu--cart i').removeClass('icon-warenkorb_leer').addClass('icon-warenkorb_voll');
                                }
                                $addToCart.find('.ekim--details--cartAmount').text(data.amount == 0 ? '' : data.amount);
                            } else {
                                ApxDialog.alert('Es ist ein Fehler aufgetreten.');
                            }
                        });

                    }
                });
            })(self, surface);
        });
    },
    initCalculator: function () {
        var self = this;

        if (!eKiMProductPrices) {
            alert('ERROR: No prices given.');
            throw 'ERROR: No prices given.';
            return;
        }

        this.$prices = this.$elem.find('.ekim--details--prices');
        this.$quantity = this.$elem.find('input[name=quantity]');

        this.texts = {
            unit: this.$prices.data('unit'),
            quantity: this.$prices.data('quantity'),
            total: this.$prices.data('total')
        };

        this.$quantity.on('change keyup', function () {
            if(this.value == '0') {
                this.value = '';
            }
            self.quantity = this.value;
            self.calculatePrices();
        });
        this.$elem.on('click', '.ekim--details--clearQuantity', function (e) {
            e.preventDefault();

            self.$quantity.val('').trigger('change');
        }).on('click', '.ekim--details--clearDiscount', function (e) {
            e.preventDefault();

            self.$discount.val('').trigger('change');
        });
        this.$discount.on('change input', function () {
            eKiMProductPrices.discount = this.value == '0' ? '' : this.value;
            self.calculatePrices();

            //TODO set short timeout so not every keypress will trigger a request
            if (self.ajaxSetDiscount) {
                self.ajaxSetDiscount.abort();
            }
            self.ajaxSetDiscount = $.post(self.$discount.data('url'), {
                _token: self.csrfToken,
                discount: eKiMProductPrices.discount
            });

            self.ajaxSetDiscount.then(function (data) {
                self.ajaxSetDiscount = false;
                if (data) {
                    // saved successfully
                    self.$discount.val(data.discount);
                    if (eKiMProductPrices.discount != data.discount) {
                        eKiMProductPrices.discount = data.discount;
                        self.calculatePrices();
                    }
                } else if (!eKiMProductPrices.discount) {
                    // discount was reset
                } else {
                    // there was some problem saving the discount
                }
            }, function (data) {
                if (data.status === 0) {
                    // canceled by user
                } else {
                    console.error('error saving discount', data);
                    //TODO translate
                    if (!self.errorShown) {
                        ApxDialog.alert('Sie wurden automatisch ausgeloggt. Ihre Änderungen werden nicht gespeichert.');
                        self.errorShown = true;
                    }
                }
            });
        });
    },
    getQuantityScale: function (quantity) {
        var scale;

        for (var _scale in eKiMProductPrices.quantityScales) {
            scale = eKiMProductPrices.quantityScales[_scale];

            if (quantity <= scale) {
                break;
            }
        }

        return scale;
    },
    getPrices: function () {
        var scale,
            prices,
            quantity = this.quantity || 0;

        if (quantity <= 0) {
            return this.changePrices(this.formatPrices(false, eKiMProductPrices.quantityScales), eKiMProductPrices.quantityScales);
        }

        quantity = parseFloat(quantity);

        scale = this.getQuantityScale(quantity);

        prices = {
            quantity: quantity,
            scale: scale,
            surfaces: {}
        };

        for (var surface in eKiMProductPrices.surfaces) {
            //                prices.surfaces[surface] = eKiMProductPrices.surfaces[surface][scale];
            prices.surfaces[surface] = {};
            prices.surfaces[surface][quantity] = eKiMProductPrices.surfaces[surface][scale];
        }

        return prices;
    },
    calculatePrices: function () {
        var scales = eKiMProductPrices.quantityScales;
        if (this.quantity) {
            scales = [this.quantity, 'total'];
        }
        this.changePrices(this.formatPrices(this.getPrices(), scales), scales);
    },
    calculatePrice: function (price, discount, quantity) {
        if (!quantity) {
            quantity = 1;
        }
        if (!discount) {
            return quantity * price;
        }
        return quantity * (price - (price / 100 * discount));
    },
    formatPrices: function (prices, scales) {

        var scales;
        if (prices) {} else {
            prices = eKiMProductPrices;
        }
        var decimals = {
                discounts: {},
                scales: {}
            },
            formattedPrices = {};

        // if at least one number in a column has decimals, set them for the whole column
        for (var surface in prices.surfaces) {
            for (var _scale in scales) {
                var scale = scales[_scale],
                    price;
                if (scale === 'total') {
                    price = prices.surfaces[surface][prices.quantity];
                    if ((price * prices.quantity) % 1 !== 0) {
                        decimals.total = 2;
                    }
                    if (eKiMProductPrices.discount) {
                        if (this.calculatePrice(price, eKiMProductPrices.discount, prices.quantity) % 1 !== 0) {
                            decimals.total_discount = 2;
                        }
                    }
                } else {
                    price = prices.surfaces[surface][scale];
                    if (price % 1 !== 0) {
                        decimals.scales[scale] = 2;
                    }
                    if (eKiMProductPrices.discount) {
                        if (this.calculatePrice(price, eKiMProductPrices.discount) % 1 !== 0) {
                            decimals.discounts[scale] = 2;
                        }
                    }
                }
            }
        }

        var formattedPrice;
        for (var surface in eKiMProductPrices.surfaces) {
            // scale price
            formattedPrices[surface] = [];

            for (var _scale in scales) {
                var scale = scales[_scale];
                if (scale === 'total') {
                    // total price
                    formattedPrice = this.formatNumber(prices.quantity * prices.surfaces[surface][prices.quantity], decimals.total);

                    if (eKiMProductPrices.discount) {
                        formattedPrice = [formattedPrice];
                        formattedPrice.push(this.formatNumber(this.calculatePrice(prices.surfaces[surface][prices.quantity], eKiMProductPrices.discount, prices.quantity), decimals.total_discount));
                    }

                    formattedPrices[surface]['total'] = formattedPrice;
                } else {
                    // scale price
                    formattedPrice = this.formatNumber(prices.surfaces[surface][scale], decimals.scales[scale]);

                    if (eKiMProductPrices.discount) {
                        formattedPrice = [formattedPrice];
                        formattedPrice.push(this.formatNumber(this.calculatePrice(prices.surfaces[surface][scale], eKiMProductPrices.discount), decimals.discounts[scale]));
                    }
                    formattedPrices[surface][scale] = formattedPrice;
                }
            }
        }

        return formattedPrices;

        //            } else {
        //                var decimals = {
        //                    scales: [],
        //                    discounts: []
        //                },
        //                    formattedPrices = {};
        //
        //                for(var surface in eKiMProductPrices.surfaces) {
        //                    for(var scale in eKiMProductPrices.surfaces[surface]) {
        //                        var price = eKiMProductPrices.surfaces[surface][scale];
        //                        if(price % 1 !== 0) {
        //                            decimals.scales[scale] = 2;
        //                        }
        //                        if(eKiMProductPrices.discount) {
        //                            if(this.calculatePrice(price, eKiMProductPrices.discount) % 1 !== 0) {
        //                                decimals.discounts[scale] = 2;
        //                            }
        //                        }
        //                    }
        //                }
        //
        //                var formattedPrice;
        //                for(var surface in eKiMProductPrices.surfaces) {
        //                    formattedPrices[surface] = {};
        //
        //                    for(var scale in eKiMProductPrices.surfaces[surface]) {
        //                        var price = eKiMProductPrices.surfaces[surface][scale];
        //                        // scale price
        //
        //                        formattedPrice = this.formatNumber(price, decimals.scales[scale]);
        //
        //                        if(eKiMProductPrices.discount) {
        //                            formattedPrice = [formattedPrice];
        //                            formattedPrice.push(this.formatNumber(this.calculatePrice(price, eKiMProductPrices.discount), decimals.discounts[scale]));
        //                        }
        //
        //                        formattedPrices[surface][scale] = formattedPrice;
        //                    }
        //
        //                }
        //
        //                return formattedPrices;
        //            }
    },
    changePrices: function (prices, scales) {

        var self = this;

        // first remove all prices
        this.$prices.find('.ekim--details--prices--quantity, .ekim--details--prices--quantityPrice').remove();


        this.$prices.find('tr').each(function () {
            var $this = $(this),
                isHeader = $this.data('is-header'),
                surface = $this.data('surface'),
                $list = $this.find('.ekim--details--prices--list'),
                $data = $('<tr>'),
                scale,
                content,
                surfacePrice;

            for (var _scale in scales) {
                scale = scales[_scale];
                if (isHeader) {
                    if (scale === 'total') {
                        $data.append('<th class="ekim--table--currency ekim--details--prices--quantity ekim--details--prices--total">' + self.texts.total + '</th>');
                    } else {
                        $data.append('<th class="ekim--table--currency ekim--details--prices--quantity">' + self.texts.quantity.replace(':quantity', scale) + '</th>');
                    }
                } else {
                    content = prices[surface][scale];
                    if ($.isArray(prices[surface][scale])) {
                        //                            for(var q in self.texts.quantity) {
                        //                            }
                        content = prices[surface][scale][0] + ' (' + prices[surface][scale][1] + ')';
                    }
                    $data.append('<td class="ekim--table--currency ekim--details--prices--quantityPrice' + (scale === 'total' ? ' ekim--details--prices--total' : '') + '">' + content + '</td>');
                }
                if ($list) {
                    $list.before($data.children());
                } else {
                    $this.append();
                }
            }
        });
    },
    formatNumber: function (number, decimals) {
        //            if(decimals && decimals > 0) {
        //                number = number.toFixed(decimals);
        //            }
        // always round up
        number = Math.ceil(number);

        number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");

        return number;
    }
};

export {
    ProductDetail
};