import {
    ApxEventManager
} from "./apxEventManager";

const $ = require('jquery');

var ApxDropdown = function ($select, options) {

    var self = this;

    this.$dropdown;
    this.$select = $select;
    this.isMultiple = !!$select.attr('multiple');
    this.name = this.$select.attr('name');
    this.settings = $.extend({}, this.defaults, options);
    this.entries = {};

    init();

    function init() {
        ApxEventManager.eventify(self);

        self.$dropdown = self.createHtml();
        self.$dropdown.data('dropdown', self);
        self.$select.hide().after(self.$dropdown);
        self.setEvents();
    }
};
ApxDropdown.prototype = {
    defaults: {
        html: '\
                <div class="apxDropdown {{ class }}"> \
                    <div class="apxDropdown--dropdown"> \
                        <div class="apxDropdown--title">{{ title }}</div>{{ icon }} \
                    </div> \
                    <div class="apxDropdown--list"> \
                        <ul> \
                        {{ listItems }} \
                        </ul> \
                    </div> \
                </div>',
        listItemHtml: '\
                <li> \
                    <label> \
                        <input type="{{ type }}" name="{{ name }}" data-value="{{ value }}"/> {{ text }} \
                    </label> \
                </li> \
                ',
        closeOnClickOnAnotherScrollMenu: false,
        iconHtml: '<div class="icon-dropdown"></div>'
    },
    createHtml: function () {
        var self = this,
            html = this.settings.html,
            $html,
            $listItems = $('<ul>'),
            $listItem,
            //                radioName = this.name + '_' + Math.round(Math.random() * 10),
            //                radioName = '',
            radioName = Math.round(Math.random() * 10),
            entry;

        html = html.replace('{{ class }}', 'apxDropdown-' + (this.isMultiple ? 'multiple' : 'single'));
        html = html.replace('{{ title }}', this.$select.data('title') || '');
        html = html.replace('{{ icon }}', this.settings.iconHtml);

        this.$select.children().each(function () {
            var $this = $(this);
            entry = self.addEntry({
                text: $this.text(),
                value: this.value !== undefined ? this.value : $this.text(),
                name: radioName,
                //                    selected: this.selected
                selected: false,
                dropdown: self
            });
            $listItem = $(self.settings.listItemHtml.replace('{{ text }}', entry.text)
                .replace('{{ value }}', entry.value)
                .replace('{{ type }}', self.isMultiple ? 'checkbox' : 'radio')
                .replace('{{ name }}', entry.name)
            ).data('entry', entry).attr('data-value', entry.value);

            //                $listItem.find('input').attr('checked', entry.selected);
            $listItems.append($listItem);

            self.entries[entry.value] = entry;
        });

        html = html.replace('{{ listItems }}', '');
        $html = $(html);
        $html.find('.apxDropdown--list > ul').append($listItems.children());
        return $html;
    },
    setEvents: function () {
        var self = this;

        this.$dropdown.on('click', '.apxDropdown--dropdown', function () {
            self.toggleList();
        }).on('click', function (e) {
            e.originalEvent.clickedScrollMenu = self;
        }).on('change', ':checkbox, :radio', function () {
            var entry = $(this).closest('li').data('entry');

            if (!self.isMultiple) {
                for (var value in self.entries) {
                    self.entries[value].setSelected(false);
                }
            }

            entry.setSelected(this.checked);
        });

        if (self.$select.attr('data-autoWidth')) {
            // calculate absolute width of the dropdown (and therefore all entries)
            var width = self.$dropdown.children('.apxDropdown--list').width();
            self.$dropdown.find('.apxDropdown--title').width(width);
        }

        self.$select.on('change', function () {
            var $options = $(this).children(),
                changedEntries = [];

            if (!self.isMultiple) {
                //                    $options = $options.filter(':selected');
            }

            $options.each(function () {
                var entry = self.selectEntry(this.value, this.selected);
                if (entry !== false) {
                    changedEntries.push(entry);
                }
            });

            for (var i in changedEntries) {
                self.fire('changed', {
                    dropdown: self,
                    entry: changedEntries[i]
                });
            }
        }).trigger('change');
    },
    selectEntry: function (value, selected) {
        var entry = this.entries[value];

        if (entry) {
            if (entry.setSelected(selected) !== false) {
                return entry;
            }
        }
        return false;
    },
    addEntry: function (data) {
        var self = this,
            entry = new DropdownEntry(data);

        entry.addListener('selectedChanged', function (e) {
            // (de-)select in select
            if (self.isMultiple) {
                self.$select.find('option[value="' + e.data.entry.value + '"]').prop('selected', e.data.entry.selected);
            } else {
                if (e.data.entry.selected) {
                    self.$select.val(e.data.entry.value);
                }
            }
            // (de-)select in dropdown
            self.$dropdown.find('li[data-value="' + e.data.entry.value + '"]').find(':checkbox, :radio').prop('checked', e.data.entry.selected);

            if (!self.isMultiple) {
                self.toggleList(false);
                self.$dropdown.find('.apxDropdown--title').text(e.data.entry.text);
            }

            self.fire('changed', {
                dropdown: self,
                entry: e.data.entry
            });
        });

        return entry;
    },
    toggleList: function (show) {
        this.$dropdown.toggleClass('active', show);
        this.fire(show ? 'listShown' : 'listHidden');
    }
};

var DropdownEntry = function (data) {
    this.name = data.name;
    this.value = data.value;
    this.text = data.text;
    this.selected = data.selected;
    this.dropdown = data.dropdown;

    ApxEventManager.eventify(this);

    this.setSelected = function (_selected) {
        if (this.selected !== _selected) {
            this.selected = _selected;
            this.fire('selectedChanged', {
                entry: this
            });
            return true;
        }
        return false;
    };
    this.setName = function (_name) {
        if (this.name !== _name) {
            this.name = _name;
            this.fire('nameChanged', {
                entry: this
            });
            return true;
        }
        return false;
    };
    this.setValue = function (_value) {
        if (this.value !== _value) {
            this.value = _value;
            this.fire('valueChanged', {
                entry: this
            });
            return true;
        }
        return false;
    };
    this.setText = function (_text) {
        if (this.text !== _text) {
            this.text = _text;
            this.fire('textChanged', {
                entry: this
            });
            return true;
        }
        return false;
    };
};

ApxDropdown.closeAll = function (dontClose) {
    $('.apxDropdown').each(function () {
        var dropdown = $(this).data('dropdown');
        if (!dontClose || (dropdown.settings.closeOnClickOnAnotherScrollMenu && dontClose !== dropdown)) {
            dropdown.toggleList(false);
        }
    });

};

$('body').on('click', function (e) {
    ApxDropdown.closeAll(!e.originalEvent || e.originalEvent.clickedScrollMenu);
});

export {
    ApxDropdown
};