import {
    ApxDropdown
} from "./apxDropdown";
import {
    ApxTagList
} from "./apxTagList";

var ProductFilter = function ($element, options) {
    var settings = $.extend({}, this.defaults, options);
    var self = this,
        filters = [],
        filterURL = $element.attr('action'),
        tagList;

    if (!$element.length || $element.data('productFilter')) {
        // no element or already initialized
        return;
    }

    var $list = $('.ekim--list-products'),
        $productList = $list.find('ul > li > ul');

    init();

    function init() {

        $element.find('.apxTagList').each(function () {
            tagList = new ApxTagList($(this));

            tagList.addListener('tagRemoved', function (e) {
                e.data.tag.dropdown.selectEntry(e.data.tag.entry.value, false);
            });
        }).end().find('.ekim--productFilter--dropdown').each(function () {

            // initialize all filter dropdowns
            var filter = new ApxDropdown($(this));
            filter.addListener('changed', function (e) {
                onSelectChange(e.data.dropdown, e.data.entry);
            });

            // scan initially selected values
            for (var e in filter.entries) {
                onSelectChange(filter, filter.entries[e]);
            }
            filters.push(filter);
        }).end().on('click', '.ekim--productFilter--reset', function (e) {
            e.preventDefault();

            onFilterReset();
        });
    }

    function onSelectChange(dropdown, entry) {
        if (entry.selected) {
            tagList.addTag({
                dropdown: dropdown,
                entry: entry,
                value: dropdown.name + '_' + entry.value,
                name: entry.text
            });
        } else {
            tagList.removeTag(dropdown.name + '_' + entry.value);
        }
        onFilterChanged();
    }

    var currentRequest;

    function onStartLoading() {
        $list.addClass('ekim--list-loading');
    }

    function onStopLoading() {
        $list.removeClass('ekim--list-loading');
    }

    function onFilterChanged() {
        //TODO add again after list rewrite
        //            if(!self.loaded) {
        //                // only load ajax after page loaded
        //                return;
        //            }

        loadResults($element.serialize());
    }

    function onFilterReset() {
        $element.find('select').val('').trigger('change');
    }

    function loadResults(filterData = {}) {
        if (currentRequest) {
            currentRequest.abort();
        }

        onStartLoading();
        currentRequest = $.ajax({
            type: 'POST',
            url: filterURL,
            data: filterData,
            dataType: 'json'
        });

        currentRequest.then(function (data) {
            var newList = '';

            $.each(data, function (i, product) {
                newList += '<li><a href="' + product.url + '">' +
                    '<span class="' + product.material_original + '">' + product.material + '</span> ' +
                    product.name + '</a></li>';
            });

            $productList.empty().append(newList);

            currentRequest = false;

            onStopLoading();

            //                console.log(data);
        }, function (error) {
            // status = 0 => canceled
            // status = 401 => not logged in
            // status = 500 => server error
            switch (error.status) {
                // canceled by user
                case 0:
                    break;
                case 401:
                    document.location.reload();
                    break;
                case 500:
                    loadResults(filterData);
                    break;
            }
        });
    }
};

ProductFilter.prototype.defaults = {};

export {
    ProductFilter
};