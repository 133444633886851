const $ = window.$ = window.jQuery = require('jquery');
require('./vendor/typeahead.bundle.min');

var Search = {
    init: function () {
        const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

        var $q = $('.ekim--search--form input[name=q]'),
            details_url = eKiM.urls.root + 'details' + '/';

        function typeahead_template(item) {
            var entry = '<a href="#" data-id="' + item.id + '" title="' + item.title + '">';
            if (item.material) {
                entry += '<span class="ekim--product--material ' + item.original.material + '">' + item.material + '</span>';
            }
            entry += item.category;
            entry += ' &raquo; ';
            entry += item.subcategory;
            entry += ' &raquo; ';
            entry += item.title;

            entry += '</a>';
            return entry;
        }

        var productSource = new Bloodhound({
            dupDetector: function (remoteMatch, localMatch) {
                return remoteMatch.id == localMatch.id;
            },
            datumTokenizer: function (d) {
                //					return Bloodhound.tokenizers.whitespace(d.tokens);
                return [d.name, d.material, d.type].join(' ');
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            //        			prefetch: opts.base_url_full + 'search/user/',
            remote: {
                url: eKiM.urls.root + 'search/%QUERY',
                ajax: {
                    type: "POST",
                    data: {
                        _token: csrfToken,
                        q: function () {
                            return $q.val();
                        }
                    }
                }
            }
        });
        productSource.initialize();

        $q.each(function () {
            var $this = $(this);
            $this.typeahead({
                hint: true,
                highlight: true
            }, [{
                name: 'products',
                source: productSource.ttAdapter(),
                displayKey: 'title',
                templates: {
                    suggestion: typeahead_template
                    //                            header: '<h3>Products</h3>'
                }
            }]).on('typeahead:selected ', function (e, data, dataset) {
                //:autocompleted = Hint accepted (arrow right)
                //			]).on('typeahead:selected typeahead:autocompleted', function(e, data,dataset){
                window.location.href = details_url + data.id;
            });
        });
    }
};

export {
    Search
};