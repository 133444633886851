import {
    ApxEventManager
} from "./apxEventManager";

var ApxTagList = function ($element, options) {

    this.settings = $.extend({}, this.defaults, options);

    this.$element = $element;
    this.$tagList;
    this.values = [];
    this.tags = {};

    this.init();
};


ApxTagList.prototype = {
    defaults: {
        html: '\
                <ul class="apxTagList--list"> \
                </ul>',
        tagHtml: '\
                <li data-value="{{ value }}"> \
                    <span class="apxTagList--tag"> \
                        {{ name }} {{ close }} \
                    </span> \
                </li>',
        closeHtml: '<a class="apxTagList--tag--delete"><i class="icon-close"></i></a>',
        tags: []
    },
    init: function () {
        ApxEventManager.eventify(this);

        this.$tagList = $(this.settings.html);
        this.$tagList.data('apxTagList', this);
        this.$element.append(this.$tagList);
        for (var tag in this.settings.tags) {
            this.addTag(tag);
        }
        this.setEvents();
    },
    addTag: function (tag) {
        if (this.indexOf(tag.value) !== -1) {
            return;
        }

        var tagHtml = this.settings.tagHtml;

        tagHtml = tagHtml
            .replace('{{ value }}', tag.value)
            .replace('{{ name }}', tag.name)
            .replace('{{ close }}', this.settings.closeHtml);

        this.values.push(tag.value);
        this.tags[tag.value] = tag;

        this.$tagList.append(tagHtml);

        this.fire('tagAdded', {
            tag: tag,
            value: tag.value
        });
    },
    removeTag: function (value) {
        if (typeof value === 'object') {
            // value is probably a tag object
            value = value.value;
        }

        var index = this.indexOf(value),
            tag = this.tags[value];

        if (index !== -1) {
            this.values.splice(index, 1);

            this.$tagList.find('li[data-value="' + value + '"]').remove();

            this.fire('tagRemoved', {
                tag: tag,
                value: value
            });
        }
    },
    indexOf: function (value) {
        if (typeof value === 'object') {
            // value is probably a tag object
            value = value.value;
        }

        var index = $.inArray(value, this.values);

        return index;
    },
    setEvents: function () {
        var self = this;
        this.$tagList.on('click', '.apxTagList--tag--delete', function (e) {
            e.preventDefault();

            var value = $(this).closest('li').data('value');
            self.removeTag(value);
        });
    }
};

export {ApxTagList};