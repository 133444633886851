var Notes = {
    $notes: false,
    $icon: false,
    $content: false,
    $contentTextarea: false,
    isEditing: false,
    url: '',
    init: function ($notes) {
        this.csrfToken = document.querySelector('meta[name="csrf-token"]').content;
        var self = this;
        this.$notes = $notes;
        this.$icon = this.$notes.find('.ekim--details--notes--icon');
        this.$content = this.$notes.find('.ekim--details--notes--content');
        this.$contentTextarea = $('<textarea maxlength="1000">').hide().insertAfter(this.$content);
        this.url = this.$notes.data('url');

        this.$icon.find('.ekim--details--notes--icon--save').hide();

        this.$icon.on('click', function (e) {
            e.preventDefault();

            if (self.isEditing) {
                self.endEdit();
            } else {
                self.startEdit();
            }
        });
    },
    startEdit: function () {
        this.isEditing = true;

        this.$icon.find('.ekim--details--notes--icon--save').show();
        this.$icon.find('.ekim--details--notes--icon--edit').hide();

        this.$content.hide();
        this.$contentTextarea.show().val(this.$content.text());
    },
    endEdit: function () {
        this.isEditing = false;

        this.$icon.find('.ekim--details--notes--icon--save').hide();
        this.$icon.find('.ekim--details--notes--icon--edit').show();

        var text = this.$contentTextarea.val();
        this.save(text);
    },
    setText: function (text) {
        this.$content.show().text(text);
        this.$contentTextarea.hide();
    },
    save: function (text) {
        var self = this;

        $.post(this.url, {
            _token: this.csrfToken,
            text: text
        }).then(function (note) {
            if (note) {
                self.setText(note.text);
            } else {
                console.error('Notiz konnte nicht gespeichert werden!', note);
            }
        });
    }
};

export {
    Notes
};